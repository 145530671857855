@media (max-width: 576px) {
    .card {
        transition: 0.3s;
        width: 320px;
        border: 1px solid #dbdfe2;
        border-radius: 5px;
    }
}

@media (min-width: 577px) {
    .card {
        transition: 0.3s;
        width: 180px;
        border: 1px solid #dbdfe2;
        border-radius: 5px;
    }
}




.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
}



.card-md {
    transition: 0.3s;
    width: 400px;
    border: 1px solid #dbdfe2;
    border-radius: 5px;
}

.card-md-mobile {
    transition: 0.3s;
    width: 320px;
    border: 1px solid #dbdfe2;
    border-radius: 5px;
}



.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.header {
    background-color: #000000;
    padding: 5px 5px 5px 5px;
    font-weight:700;
    color:white;
    border-radius: 5px 5px 0 0;
}

.item-container {
    display: flex;
    padding: 3px 3px 3px 3px;
}
.item {
    width: 50%;
    white-space: nowrap;
    padding-right:5px;
}

.item-full-width {
    width: 100%;
    white-space: nowrap;
    padding-right:5px;
}

[contenteditable] {
    outline: 0px solid transparent;
}

.header-item {
    padding: 3px 3px 3px 3px;
    background-color: #ffdf91;
}

.img-fit {
    width: 100%; 
    height: 100%;
    object-fit: contain;
}

.bottom {
    border-top: 1px solid #ffdf91;
}

.bold-text {
font-weight: bolder;
}


.text-right {
    text-align: right;
}
    
