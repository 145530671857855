@font-face {
  font-family: "Helvetica Neue LT W01_35 Thin";
  src: url("../public/4ff9f3fa-9221-4fc5-97e6-93572b6efa24.woff2") format("woff2")
}

@font-face {
  font-family: "Helvetica Neue LT W01_41488878";
  src: url("../public/3a46542f-f429-4821-9a88-62e3be54a640.woff2") format("woff2")
}

@font-face {
  font-family: "Helvetica Neue LT W01_65 Md";
  src: url("../public/5b1fbd62-45dc-4433-a7df-a2b24a146411.woff2") format("woff2")
}


a {
  color: #313131;
}

a:focus {
  outline: none;
  }

body {
  font-family:Helvetica Neue LT W01_41488878;
  font-size: 14px;
  line-height: normal;
}
body {
  background-color: #"fff";
  margin: 0 auto;
}
body {
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

@media (min-width: 0px) {
  h1 {
    font-size: 30px;
  }

  .head-text{
    width: 100%;
  }

  .content-container{
    width: 100%;
  }

  .hide-topmenu{
    display: none;
  }

  .hide-topmenu-icon{
    display: inline;
  }

}

@media (min-width: 480px) {
  h1 {
    font-size: 35px;
  }

  .head-text{
    width: 100%;
  }

  .content-container{
    width: 100%;
  }

  .hide-topmenu{
    display: none;
  }

  .hide-topmenu-icon{
    display: inline;
  }

  .main-logo
  {
    margin-left:30px;
  }

}

@media (min-width: 768px) {
  h1 {
    font-size: 40px;
  }

  .head-text{
    width: 70%;
  }

  .content-container{
    width: 100%;
  }

  .hide-topmenu{
    display: none;
  }
  .hide-topmenu-icon{
    display: inline;
  }

  .main-logo
  {
    margin-left:30px;
  }

}

@media (min-width: 992px) {
  h1 {
    font-size: 45px;
  }

  .head-text{
    width: 70%;
  }

  .content-container{
    width: 80%;
  }

  .hide-topmenu{
    display: inline;
  }
  .hide-topmenu-icon{
    display: none;
  }

  .main-logo
  {
    width:21%;
  }

}

@media (min-width: 1200px) {
  h1 {
    font-size: 60px;
  }

  .head-text{
    width: 60%;
  }

  .content-container{
    width: 65%;
  }

  .hide-topmenu{
    display: inline;
  }

  .hide-topmenu-icon{
    display: none;
  }

  .main-logo
  {
    width:21%;
  }

}



h1 {
    font-family: Helvetica Neue LT W01_35 Thin;
    margin: 0 0 15px;
}

h2 {
  font-family: Helvetica Neue LT W01_35 Thin;
  margin: 0 0 15px;
}

b, h1, h3, h4, h5, p {
    font-weight: 400;
    margin: 0;
}


.header-main {
  background-color: #fff;
  height: 75px;
  padding: 30px 0 20px;
  border-bottom: 3px solid #FDB913;
}

.header-main-container {
  min-width: 100vw;
  width: 100%;
  display: flex;
  align-items: center;
/*  justify-content: center;*/
}

.header-main ul {
  list-style: none;
  margin: 0;
  padding-top: 10px;

}

.header-container-img{
  margin-right: 80px;
}

.header-main ul li {
  float: left;
}

.header-main li a {
  text-decoration: none;
  padding: 8px 12px 10px;
  font-size: 16px;
}


.header-main ul li a:hover {
  background-color: #FDB913;
}



.set-underling {
  border-bottom:2px solid #FDB913;
  padding-bottom:2px;
}


.head-text {
  padding-top: 40px;
  padding-bottom: 20px;
}

.head-text h1 {
  text-align: center;
}

.head-text-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-container
{
  display: flex;
  align-items: top;
  justify-content: center;
}

.content-container div {
  padding-left: 25px;
  padding-right: 25px;
}

.content-container p {
  font-size: 16px;
  margin: 0 0 10px;
  line-height: 1.4;
}

.content-container ul {
  list-style: disc;
  padding: 0 0 0 15px;
  font-size: 16px;
  margin: 0 0 23px;
  line-height: 1.4;
}

.content-container strong {
  font-size: 16px;
  font-weight: 400;
  font-family: Helvetica Neue LT W01_65 Md;
}


.spit {
  width: 130%;
}

.center-content{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.button-container{
  width: 100%;
  display: flex;
  align-items: center;
}


.upload-container{
  display: flex;
  background-color: #ffdf91;
  height: 50px;
  width: 103%;
  outline: 2px dashed black;
  outline-offset: -10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


.menu-icon{
  width: 25px;
  height: 25px;
  left: 0;
  fill: #313131;
  cursor: pointer;
}

.mobileLogo{
  height: 30px;
  width: auto;
}

/* override panel */

#override-panel .ms-Panel-content {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 20px;
}

.header-main-navigation-mobile{
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: block;
  position: absolute;
}

.header-main-navigation-mobile li a {
  display: block;
  outline: none;
  padding: 15px 100px 0 0;
  min-height: 30px;
  position: relative;
  color: #313131;
}

.header-main-navigation-mobile li a {
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}

.left-menu-header{
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 14px;
}

.header-main-navigation-mobile li {
  list-style: none;
  line-height: 1;
  padding: 0 15px;  
  border-bottom: 2px solid #dbdfe2;
}

.fixed-nav-left {
  position: fixed;
  height: 50px;
  width: 50px;
  top: 132px;
  left: 0px;
  background-color: black;
  border-radius: 0 10px 10px 0;
}



.template-page-header {
  font-size: 22px;
  font-weight: 600;
  padding-bottom: 12px;
  display: inline-block
}


.template-text-header {
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 2px
}

.template-text {
  font-size: 14px;
  padding-bottom: 8px;
  max-width: 800px
}


.choose-language-container{
  position: absolute;
  width: 100%;
}

.choose-language{
  padding-top: 3px;
  padding-right: 3px;
  float:right;
}